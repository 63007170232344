<template>
  <MainLayout
    bg-color="white"
    no-content-padding
    scroll-toolbar
  >
    <template #toolbar>
      <div class="relative flex items-center justify-between px-5 py-3.5 border-b border-border-normal">
        <div class="flex items-center">
          <div class="ml-3">
            <BaseText
              type="label"
              size="md"
              class="text-neutral-900 font-medium"
            >
              Lens
            </BaseText>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <!-- TODO @sam: extract into a component when design is finalized -->
      <div class="px-3 py-2">
        <div class="px-2.5 rounded-lg border border-border-normal">
          <div class="flex items-center justify-between px-5 py-4">
            <div>
              <BaseText
                type="label"
                size="md"
                class="text-neutral-900 font-medium"
              >
                Reports
              </BaseText>
            </div>
            <div>
              <BaseButton
                outlined
                type="primary"
                size="sm"
                @click="show.createReportModal = true"
              >
                Create Report
              </BaseButton>
            </div>
          </div>
          <!-- Report list table -->
          <div>
            <div class="grid grid-cols-12 bg-background-disabled rounded-md">
              <div
                v-for="(header, index) in headers"
                :key="index"
                :class="`col-span-${header.span} px-2 py-3`"
              >
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ header.label }}
                </BaseText>
              </div>
            </div>
            <div v-if="lensReports.length> 0">
              <div
                v-for="report in lensReports"
                :key="report.id"
                class="grid grid-cols-12 text-text-muted cursor-pointer hover:bg-neutral-10"
                @click="navToReport(report.id)"
              >
                <div class="px-2 py-3 col-span-4">
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    {{ report.name }}
                  </BaseText>
                </div>
                <div class="px-2 py-3 col-span-4">
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    {{ report.user_id }}
                  </BaseText>
                </div>
                <div class="px-2 py-3 col-span-3">
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    {{ formatDate(report.updated_at) }}
                  </BaseText>
                </div>
                <div class="col-span-1 justify-self-end self-center mr-2">
                  <button
                    v-on-clickaway="() => { expandedReportOptions = null }"
                    class="relative option-menu-button p-0.5 rounded-md z-20"
                    :class="expandedReportOptions === report.id ? 'active' : 'inactive'"
                    @click.stop.prevent="expandedReportOptions = expandedReportOptions === report.id ? null : report.id"
                  >
                    <KebabMenuIcon />
                    <transition name="option">
                      <div
                        v-if="expandedReportOptions === report.id"
                        class="report-options flex flex-col gap-0.5 p-1 rounded-lg bg-white"
                      >
                        <button
                          class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
                        >
                          <ChangeFolderIcon class="transition-colors text-icon-normal group-hover:text-icon-muted" />
                          <BaseText
                            type="body"
                            size="sm"
                            class="transition-colors text-text-normal group-hover:text-text-muted"
                          >
                            Move
                          </BaseText>
                        </button>
                        <button
                          class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
                          @click="handleReportDelete(report.id)"
                        >
                          <DeleteIcon class-name="transition-colors text-icon-normal group-hover:text-icon-muted" />
                          <BaseText
                            type="body"
                            size="sm"
                            class="transition-colors text-text-normal group-hover:text-text-muted"
                          >
                            Remove
                          </BaseText>
                        </button>
                      </div>
                    </transition>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modals -->
      <NewReportModal
        v-if="show.createReportModal"
        :lens-id="lensId"
        @close="show.createReportModal = false"
      />
    </template>
  </MainLayout>
</template>

<script>
import LensAPI from '@/api/lens'
import MainLayout from '../../layouts/MainLayout.vue'

import { mixin as clickaway } from 'vue-clickaway2'
// Components
import NewReportModal from '../../components/lens/NewReportModal.vue'
// Icons
import KebabMenuIcon from '../../components/globals/Icons/KebabMenuIcon.vue'
import ChangeFolderIcon from '../../components/globals/Icons/ChangeFolderIcon.vue'
import DeleteIcon from '../../components/globals/Icons/DeleteIcon.vue'

export default {
  name: 'LensDashboardView',
  components: {
    MainLayout,

    NewReportModal,

    KebabMenuIcon,
    DeleteIcon,
    ChangeFolderIcon
  },
  mixins: [clickaway],
  data () {
    const headers = [
      { label: 'Report Name', span: 4 },
      { label: 'Created By', span: 4 },
      { label: 'Last Updated', span: 3 }
    ]
    return {
      headers,

      expandedReportOptions: null,
      lensReports: [],
      lensId: this.$route.params.lensId || null,
      show: {
        createReportModal: false
      }
    }
  },
  async mounted () {
    const res = await LensAPI.LensReport.getAll(this.lensId)
    console.log(res)
    this.lensReports = res.data
  },
  methods: {
    navToReport (reportId) {
      this.$router.push({ name: 'LensReport', params: { lensId: this.lensId, reportId } })
    },
    formatDate (dateMS) {
      const date = new Date(dateMS)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return date.toLocaleDateString('en-US', options)
    },
    async handleReportDelete (reportId) {
      console.log('%c Deleting Report', 'color: pink; font-size: 14px;')
      try {
        await LensAPI.LensReport.remove(reportId)
        this.lensReports = this.lensReports.filter(report => report.id !== reportId)
      } catch (e) {
        this.$showAlert({
          type: 'error',
          message: 'Deleting report error'
        })
      }
      this.$showAlert({
        type: 'success',
        message: 'Report deleted'
      })
    }
  }
}
</script>

<style scoped>
.option-enter-active,
.option-leave-active {
  transition: opacity 100ms ease-in-out;
}

.option-enter-from,
.option-enter,
.option-leave-to {
  opacity: 0;
}

.option-enter-to,
.option-leave-from {
  opacity: 1;
}

.option-menu-button {
  transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
}

.option-menu-button.active {
  background-color: #ECEFF3;
}

.option-menu-button.inactive:hover {
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.report-options {
  position: absolute;
  right: calc(100% + 4px);
  top: 50%;
  transform: translateY(-50%);
  width: 105px;
  z-index: 50;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}

</style>
